import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
  const styles = {
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 100,
      maxWidth: '100%',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      lineHeight: theme.typography.pxToRem(20),
      height: 30,
      borderRadius: 12,
      color: theme.palette.text.dark,
      padding: '5px 10px',
      background: theme.palette.CHANGEME[7],
      overflow: 'hidden',
      [theme.breakpoints.up('postmd')]: {
        fontSize: theme.typography.pxToRem(11),
      },
    },

    rootText: {
      margin: 'auto',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    dotRoot: {
      display: 'flex',
      alignItems: 'center',
    },

    dotCircle: {
      width: 6,
      height: 6,
      marginRight: 6,
      borderRadius: 3,
      flex: '0 0 auto',
    },

    dotText: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(12),
      whiteSpace: 'nowrap',
    },

    large: {
      '& $dotCircle': {
        width: 15,
        height: 15,
        marginRight: 10,
        borderRadius: 7,
      },

      '& $dotText': {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 700,
      },
    },
  };

  Object.keys(theme.palette.status).forEach(color => {
    styles[color] = {
      backgroundColor: theme.palette.status[color],
      borderColor: theme.palette.status[color],
    };
  });

  Object.keys(theme.palette.statuses).forEach(status => {
    styles[status] = {
      backgroundColor: theme.palette.statuses[status],
    };
  });

  return styles;
});
