import React from 'react';
import PropTypes from 'prop-types';
import humps from 'humps';
import cn from 'classnames';
import { propOr } from 'ramda';
import { truncate } from 'utils/strings';

import Tooltip from 'components/Tooltip';

import useStyles from './useStyles';

const MAX_LABEL_LENGTH = 20;

const StatusLabel = ({ className, type, status, color, size, children, dataNode }) => {
  const labelText = children || propOr('Unknown', 'name')(status);
  const classes = useStyles();

  if (type === 'dot') {
    const labelSpan = <span className={classes.dotText}>{truncate(labelText, MAX_LABEL_LENGTH)}</span>;
    const showTooltip = typeof labelText !== 'string' || labelText.length > MAX_LABEL_LENGTH;

    return (
      <div className={cn(classes.dotRoot, classes[size])} data-node={dataNode}>
        <div
          className={cn(classes.dotCircle, classes[`${status && status.color ? humps.camelize(status.color) : color}`])}
        />
        {showTooltip ? (
          <Tooltip title={labelText} addKeyboardFocus>
            {labelSpan}
          </Tooltip>
        ) : (
          labelSpan
        )}
      </div>
    );
  }

  const colorClass = status && status.color ? humps.camelize(status.color) : color;

  return (
    <div className={cn(classes.root, classes[colorClass], className)} data-node={dataNode}>
      <div className={classes.rootText}>{labelText}</div>
    </div>
  );
};
StatusLabel.propTypes = {
  className: PropTypes.string,
  status: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  }),
  color: PropTypes.oneOf(['unknown', 'success', 'danger', 'warning']),
  children: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  dataNode: PropTypes.string,
};
StatusLabel.defaultProps = {
  color: 'unknown',
  children: null,
  status: {},
  className: '',
  type: 'splash',
  size: 'small',
  dataNode: null,
};

export default StatusLabel;
